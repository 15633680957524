import React, { useState } from 'react';
import styles from './Tabs.module.css';

export interface Tab {
    label: string;
    content: React.ReactNode;
    badgeCount?: number; // Optional badgeCount property
}

interface TabsProps {
    tabs: Tab[];
    onTabChange: (tabLabel: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ tabs, onTabChange }) => {
    const [activeTab, setActiveTab] = useState(tabs[0].label);

    const handleTabClick = (label: string) => {
        setActiveTab(label);
        onTabChange(label);
    };

    return (
        <div className={styles.tabsContainer}>
            <div className={styles.tabs}>
                {tabs.map((tab) => (
                    <div
                        key={tab.label}
                        className={`${styles.tab} ${tab.label === activeTab ? styles.activeTab : ''}`}
                        onClick={() => handleTabClick(tab.label)}
                    >
                        <span className={styles.label}>
                            {tab.label}
                            {tab.badgeCount !== undefined && (
                                <span className={styles.badge}>{tab.badgeCount}</span>
                            )}
                        </span>
                    </div>
                ))}
            </div>
            <div className={styles.tabContent}>
                {tabs.find((tab) => tab.label === activeTab)?.content}
            </div>
        </div>
    );
};

export default Tabs;