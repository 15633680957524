import React, { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../../App';
import Player from '../../types/Player';
import Season from '../../types/Season';
import styles from './Profile.module.css';

interface ProfileProps {
    userId: string;
    userRole: string;
}

const Profile: React.FC<ProfileProps> = ({ userId, userRole }) => {
    const [player, setPlayer] = useState<Player | null>(null);
    const [activeSeason, setActiveSeason] = useState<Season | null>(null);
    const [pastSeasons, setPastSeasons] = useState<Season[]>([]);
    const [selectedTab, setSelectedTab] = useState<'Current' | 'Past Seasons'>('Current');
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchPlayerData = async () => {
            try {
                const playerDoc = await getDoc(doc(firestore, 'users', userId));
                if (playerDoc.exists()) {
                    const playerData = playerDoc.data() as Player;
                    setPlayer(playerData);
                }
            } catch (error) {
                console.error("Error fetching player data: ", error);
            }
        };

        const fetchSeasons = async () => {
            try {
                const seasonsCollection = collection(firestore, 'seasons');
                const activeSeasonQuery = query(seasonsCollection, where('isActive', '==', true));
                const pastSeasonsQuery = query(seasonsCollection, where('isActive', '==', false));

                const [activeSeasonSnapshot, pastSeasonsSnapshot] = await Promise.all([
                    getDocs(activeSeasonQuery),
                    getDocs(pastSeasonsQuery)
                ]);

                if (!activeSeasonSnapshot.empty) {
                    const activeSeasonData = activeSeasonSnapshot.docs[0].data() as Season;
                    setActiveSeason(activeSeasonData);
                }

                const pastSeasonsData = pastSeasonsSnapshot.docs.map(doc => doc.data() as Season);
                setPastSeasons(pastSeasonsData);
            } catch (error) {
                console.error("Error fetching seasons: ", error);
            }
        };

        Promise.all([fetchPlayerData(), fetchSeasons()]).finally(() => setLoading(false));
    }, [userId]);

    const handleUpdateProfile = async () => {
        if (player) {
            await updateDoc(doc(firestore, 'users', player.id), {
                name: player.name,
                email: player.email,
                discordId: player.discordId,
                codId: player.codId,
            });
            alert('Profile updated successfully!');
        }
    };

    const handleEnrollInSeason = async () => {
        if (player && activeSeason) {
            const updatedSeasons = [...(player.seasons || []), { seasonId: activeSeason.id, totalMerits: 0, rank: 0, meritEntries: [] }];
            await updateDoc(doc(firestore, 'users', player.id), {
                seasons: updatedSeasons,
            });
            alert('Enrolled in active season!');
        }
    };

    const renderCurrentSeason = () => (
        <div className={styles.seasonContainer}>
            <h3>Current Season: {activeSeason?.name}</h3>
            <p>Total Merits: {player?.seasons?.find(season => season.seasonId === activeSeason?.id)?.totalMerits || 0}</p>
            {!player?.seasons?.some(season => season.seasonId === activeSeason?.id) && (
                <button onClick={handleEnrollInSeason} className={styles.button}>Enroll</button>
            )}
        </div>
    );

    const renderPastSeasons = () => (
        <div className={styles.seasonContainer}>
            <h3>Past Seasons</h3>
            {pastSeasons.map(season => (
                <div key={season.id} className={styles.pastSeason}>
                    <h4>{season.name}</h4>
                    <p>Total Merits: {player?.seasons?.find(playerSeason => playerSeason.seasonId === season.id)?.totalMerits || 0}</p>
                    <p>Rank: {player?.seasons?.find(playerSeason => playerSeason.seasonId === season.id)?.rank || 'N/A'}</p>
                </div>
            ))}
        </div>
    );

    if (loading) return <p>Loading...</p>;

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Profile</h2>

            <div className={styles.profileSeasonContainer}>
                <div className={styles.profileFields}>
                    <div className={styles.formGroup}>
                        <label className={styles.label}>Name:</label>
                        <input value={player?.name || ''} onChange={(e) => setPlayer({ ...player!, name: e.target.value })} className={styles.input} />
                    </div>
                    <div className={styles.formGroup}>
                        <label className={styles.label}>Email:</label>
                        <input value={player?.email || ''} onChange={(e) => setPlayer({ ...player!, email: e.target.value })} className={styles.input} />
                    </div>
                    <div className={styles.formGroup}>
                        <label className={styles.label}>COD ID:</label>
                        <input value={player?.codId || ''} onChange={(e) => setPlayer({ ...player!, codId: e.target.value })} className={styles.input} />
                    </div>
                    <div className={styles.formGroup}>
                        <label className={styles.label}>Discord ID:</label>
                        <input value={player?.discordId || ''} onChange={(e) => setPlayer({ ...player!, discordId: e.target.value })} className={styles.input} />
                    </div>
                    <button onClick={handleUpdateProfile} className={styles.button}>Update Profile</button>
                </div>

                {/* Conditionally render the season tab container based on userRole */}
                {userRole !== 'user' && (
                <div className={styles.tabContainer}>
                    <div className={styles.tabHeaders}>
                        <button onClick={() => setSelectedTab('Current')} className={`${styles.tabHeader} ${selectedTab === 'Current' ? styles.activeTab : ''}`}>
                            Current
                        </button>
                        <button onClick={() => setSelectedTab('Past Seasons')} className={`${styles.tabHeader} ${selectedTab === 'Past Seasons' ? styles.activeTab : ''}`}>
                            Past Seasons
                        </button>
                    </div>
                    <div className={styles.tabContent}>
                        {selectedTab === 'Current' && renderCurrentSeason()}
                        {selectedTab === 'Past Seasons' && renderPastSeasons()}
                    </div>
                </div>
                )}
            </div>
        </div>
    );
};

export default Profile;