import React, { useState, useEffect } from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, User } from 'firebase/auth';
import { getFirestore, collection, getDocs, query, where, getDoc, doc, setDoc, QuerySnapshot, DocumentData } from 'firebase/firestore';
import Home from './components/Home/Home';
import Login from './components/Login/Login';
import AdminSeasons from './components/Seasons/AdminSeasons';
import Users from './components/Users/Users';
import News from './components/News/News';
import CreateAccount from './components/CreateAccount/CreateAccount';
import Profile from './components/Profile/Profile';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

export { auth, signInWithEmailAndPassword, firestore, collection, getDocs, query, where, getDoc, doc, setDoc };

export type { QuerySnapshot, DocumentData };

export default function App() {
  const [user, setUser] = useState<User | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        setUser(authUser);

        // Retrieve user role from Firestore
        const userRoleRef = doc(firestore, 'roles', authUser.uid);
        const userRoleSnap = await getDoc(userRoleRef);

        if (userRoleSnap.exists()) {
          setUserRole(userRoleSnap.data().role);
        } else {
          setUserRole(null);
        }
      } else {
        setUser(null);
        setUserRole(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogin = (user: User, role: string) => {
    setUser(user);
    setUserRole(role);
  };

  return (
      <Router>
        <Routes>
          <Route path="/" element={<Home user={user} userRole={userRole} />} />
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/admin/seasons" element={<AdminSeasons />} />
          <Route path="/users" element={<Users />} />
          <Route path="/news" element={<News />} />
          <Route
              path="/profile"
              element={user && userRole ? <Profile userId={user.uid} userRole={userRole} /> : <Navigate to="/login" />}
          />        </Routes>
      </Router>
  );
}