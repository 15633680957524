import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore } from '../../App';
import { doc, getDoc } from 'firebase/firestore';
import News from '../News/News';
import Users from '../Users/Users';
import AdminSeasons from '../Seasons/AdminSeasons';
import Profile from '../Profile/Profile';
import styles from './Home.module.css';
import MenuPosition from '../../types/MenuPosition';
import HomeProps from '../../types/props/Home.props';

const Home: React.FC<HomeProps> = ({ user, userRole }) => {
    const [selectedTab, setSelectedTab] = useState<string>('News');
    const [menuVisible, setMenuVisible] = useState<boolean>(false);
    const [adminMenuVisible, setAdminMenuVisible] = useState<boolean>(false);
    const [menuPosition, setMenuPosition] = useState<MenuPosition>({ top: 0, left: 0 });
    const [userName, setUserName] = useState<string | null>(null);
    const userButtonRef = useRef<HTMLSpanElement | null>(null);
    const adminButtonRef = useRef<HTMLButtonElement | null>(null);
    const menuDropdownRef = useRef<HTMLDivElement | null>(null);
    const adminDropdownRef = useRef<HTMLDivElement | null>(null);
    const [hoveredMenuItem, setHoveredMenuItem] = useState<string | null>(null);
    const [hoveredAdminMenuItem, setHoveredAdminMenuItem] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserName = async () => {
            if (user) {
                const userDocRef = doc(firestore, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setUserName(userData.name || user.email);
                } else {
                    setUserName(user.email); // Fallback to email if name is not available
                }
            }
        };

        fetchUserName();
    }, [user]);

    const toggleMenu = () => {
        if (userButtonRef.current) {
            const rect = userButtonRef.current.getBoundingClientRect();
            setMenuPosition({ top: rect.bottom + window.scrollY, left: rect.left + window.scrollX });
            setMenuVisible((prev) => !prev);
        }
    };

    const toggleAdminMenu = () => {
        if (adminButtonRef.current) {
            const rect = adminButtonRef.current.getBoundingClientRect();
            setMenuPosition({ top: rect.bottom + window.scrollY, left: rect.left + window.scrollX });
            setAdminMenuVisible((prev) => !prev);
        }
    };

    const handleLogout = () => {
        auth.signOut().then(() => {
            setMenuVisible(false);
            setAdminMenuVisible(false);
            setSelectedTab('News');
            navigate('/');
        });
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            (menuDropdownRef.current && !menuDropdownRef.current.contains(event.target as Node)) ||
            (adminDropdownRef.current && !adminDropdownRef.current.contains(event.target as Node))
        ) {
            if (
                !userButtonRef.current?.contains(event.target as Node) &&
                !adminButtonRef.current?.contains(event.target as Node)
            ) {
                setMenuVisible(false);
                setAdminMenuVisible(false);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const getMenuItems = () => {
        if (!user) return [{ name: 'News', action: () => setSelectedTab('News') }];

        const items = [{ name: 'News', action: () => setSelectedTab('News') }];

        if (userRole) {
            items.push({ name: 'Profile', action: () => setSelectedTab('Profile') });
        }

        if (userRole !== 'user') {
            items.push({ name: 'Talent Builds', action: () => setSelectedTab('Talent Builds') });
        }

        if (userRole === 'admin') {
            items.push({ name: 'Admin', action: toggleAdminMenu });
        }

        return items;
    };

    return (
        <div className={styles.container}>
            <header className={styles.headerContainer}>
                <div className={styles.headerTitle}>Blood Oath</div>
                <div>
                    {user ? (
                        <span
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleMenu();
                            }}
                            ref={userButtonRef}
                            className={styles.headerButtonText}
                        >
                            {userName || user.email}
                        </span>
                    ) : (
                        <button onClick={() => navigate('/login')} className={styles.headerButton}>
                            <span className={styles.headerButtonText}>Sign In</span>
                        </button>
                    )}
                </div>
            </header>

            <div className={styles.toolbar}>
                {getMenuItems().map((item, index) => (
                    <button
                        key={index}
                        onClick={item.action}
                        className={`${styles.menuButton} ${hoveredMenuItem === item.name ? styles.menuButtonHover : ''}`}
                        onMouseEnter={() => setHoveredMenuItem(item.name)}
                        onMouseLeave={() => setHoveredMenuItem(null)}
                        ref={item.name === 'Admin' ? adminButtonRef : null}
                    >
                        <span className={styles.menuButtonText}>
                            {item.name} {item.name === 'Admin' && <span className={styles.downArrow}>⌄</span>}
                        </span>
                    </button>
                ))}
            </div>

            {adminMenuVisible && (
                <div
                    ref={adminDropdownRef}
                    className={styles.adminMenuDropdown}
                    style={{ top: `${menuPosition.top}px`, left: `${menuPosition.left}px` }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <button
                        onClick={() => {
                            setSelectedTab('Users');
                            setAdminMenuVisible(false);
                        }}
                        className={`${styles.menuItem} ${hoveredAdminMenuItem === 'Users' ? styles.menuItemHover : ''}`}
                        onMouseEnter={() => setHoveredAdminMenuItem('Users')}
                        onMouseLeave={() => setHoveredAdminMenuItem(null)}
                    >
                        <span className={styles.menuItemText}>Users</span>
                    </button>
                    <button
                        onClick={() => {
                            setSelectedTab('AdminSeasons');
                            setAdminMenuVisible(false);
                        }}
                        className={`${styles.menuItem} ${hoveredAdminMenuItem === 'AdminSeasons' ? styles.menuItemHover : ''}`}
                        onMouseEnter={() => setHoveredAdminMenuItem('AdminSeasons')}
                        onMouseLeave={() => setHoveredAdminMenuItem(null)}
                    >
                        <span className={styles.menuItemText}>Seasons</span>
                    </button>
                </div>
            )}

            {menuVisible && (
                <div
                    ref={menuDropdownRef}
                    className={styles.menuDropdown}
                    style={{ top: `${menuPosition.top}px`, left: `${menuPosition.left}px` }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <button
                        onClick={handleLogout}
                        className={`${styles.menuItem} ${hoveredMenuItem === 'Logout' ? styles.menuItemHover : ''}`}
                        onMouseEnter={() => setHoveredMenuItem('Logout')}
                        onMouseLeave={() => setHoveredMenuItem(null)}
                    >
                        <span className={styles.menuItemText}>Logout</span>
                    </button>
                </div>
            )}

            <div className={styles.contentContainer}>
                <div className={styles.content}>
                    {selectedTab === 'News' && <News />}
                    {selectedTab === 'Profile' && user && userRole && <Profile userId={user.uid} userRole={userRole} />}
                    {selectedTab === 'Users' && userRole === 'admin' && <Users />}
                    {selectedTab === 'AdminSeasons' && userRole === 'admin' && <AdminSeasons />}
                </div>
            </div>

            <footer className={styles.footer}>
                <span className={styles.footerText}>© 2024 Deadly, Inc.</span>
            </footer>
        </div>
    );
};

export default Home;