import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc, getDocs, query, collection, where } from 'firebase/firestore';
import { auth, firestore } from '../../App';
import styles from './CreateAccount.module.css';
import Player from "../../types/Player";

const CreateAccount = () => {
    const [codId, setCodId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [discordId, setDiscordId] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [passwordComplexity, setPasswordComplexity] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        special: false,
    });

    const navigate = useNavigate();

    const validatePassword = (password: string) => {
        const complexity = {
            length: password.length >= 8,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            number: /[0-9]/.test(password),
            special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        };
        setPasswordComplexity(complexity);
        setPasswordMatch(password === confirmPassword);
    };

    const handleCreateAccount = async () => {
        if (!passwordMatch) {
            setErrorMessage("Passwords do not match.");
            return;
        }

        setLoading(true);

        try {
            const usersRef = collection(firestore, 'users');
            const codIdQuery = query(usersRef, where('codId', '==', codId));
            const codIdSnapshot = await getDocs(codIdQuery);

            if (!codIdSnapshot.empty) {
                setErrorMessage("COD ID already exists.");
                setLoading(false);
                return;
            }

            const emailQuery = query(usersRef, where('email', '==', email));
            const emailSnapshot = await getDocs(emailQuery);

            if (!emailSnapshot.empty) {
                setErrorMessage("Email already exists.");
                setLoading(false);
                return;
            }

            const discordQuery = query(usersRef, where('discordId', '==', discordId));
            const discordSnapshot = await getDocs(discordQuery);

            if (!discordSnapshot.empty) {
                setErrorMessage("Discord ID already exists.");
                setLoading(false);
                return;
            }

            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            const player = new Player();
            player.id = user.uid;
            player.codId = codId;
            player.name = name;
            player.email = email;
            player.discordId = discordId;
            player.verified = false;
            await setDoc(doc(firestore, "users", user.uid), player);
            await setDoc(doc(firestore, "roles", user.uid), {
                role: "user",
            });

            navigate('/');
        } catch (error: any) {
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        navigate(-1); // Go back to the previous page
    };

    return (
        <div className={styles.container}>
            <div className={styles.form}>
                <div className={styles.inputContainer}>
                    <label className={styles.label}>Email *</label>
                    <input
                        className={styles.input}
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                    />
                </div>
                <div className={styles.inputContainer}>
                    <label className={styles.label}>COD ID *</label>
                    <input
                        className={styles.input}
                        placeholder="Enter your COD ID"
                        value={codId}
                        onChange={(e) => setCodId(e.target.value)}
                        type="text"
                    />
                </div>
                <div className={styles.inputContainer}>
                    <label className={styles.label}>Name *</label>
                    <input
                        className={styles.input}
                        placeholder="Enter your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                    />
                </div>
                <div className={styles.inputContainer}>
                    <label className={styles.label}>Discord ID *</label>
                    <input
                        className={styles.input}
                        placeholder="Enter your Discord ID"
                        value={discordId}
                        onChange={(e) => setDiscordId(e.target.value)}
                        type="text"
                    />
                </div>
                <div className={styles.inputContainer}>
                    <label className={styles.label}>Password *</label>
                    <input
                        className={styles.input}
                        placeholder="Enter your password"
                        type="password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            validatePassword(e.target.value);
                        }}
                    />
                    <p className={passwordComplexity.length ? styles.valid : styles.invalid}>
                        {passwordComplexity.length ? "✔" : "✘"} Minimum 8 characters
                    </p>
                    <p className={passwordComplexity.uppercase ? styles.valid : styles.invalid}>
                        {passwordComplexity.uppercase ? "✔" : "✘"} At least one uppercase letter
                    </p>
                    <p className={passwordComplexity.lowercase ? styles.valid : styles.invalid}>
                        {passwordComplexity.lowercase ? "✔" : "✘"} At least one lowercase letter
                    </p>
                    <p className={passwordComplexity.number ? styles.valid : styles.invalid}>
                        {passwordComplexity.number ? "✔" : "✘"} At least one number
                    </p>
                    <p className={passwordComplexity.special ? styles.valid : styles.invalid}>
                        {passwordComplexity.special ? "✔" : "✘"} At least one special character
                    </p>
                </div>
                <div className={styles.inputContainer}>
                    <label className={styles.label}>Confirm Password *</label>
                    <input
                        className={styles.input}
                        placeholder="Confirm your password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            setPasswordMatch(password === e.target.value);
                        }}
                    />
                    <p className={passwordMatch ? styles.valid : styles.invalid}>
                        {passwordMatch ? "✔" : "✘"} Passwords match
                    </p>
                </div>
                {errorMessage && <p className={styles.errorText}>{errorMessage}</p>}
                <button
                    onClick={handleCreateAccount}
                    className={styles.button}
                    disabled={loading}
                >
                    {loading ? 'Creating Account...' : 'Create Account'}
                </button>
                <button
                    onClick={handleCancel}
                    className={`${styles.button} ${styles.cancelButton}`}
                    disabled={loading}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default CreateAccount;