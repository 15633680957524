import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore, signInWithEmailAndPassword, getDoc, doc } from '../../App';
import styles from './Login.module.css';
import LoginProps from "../../types/props/Login.props";

const Login: React.FC<LoginProps> = ({ onLogin }) => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const passwordInputRef = useRef<HTMLInputElement | null>(null);
    const navigate = useNavigate();

    const handleLogin = () => {
        signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                const user = userCredential.user;
                if (!user) return;

                const roleDoc = await getDoc(doc(firestore, 'roles', user.uid));

                if (roleDoc.exists()) {
                    const userRole = roleDoc.data()?.role as string;
                    console.log('Member Role:', userRole);

                    onLogin(user, userRole);
                    navigate('/');
                } else {
                    setErrorMessage('No role found for this user.');
                }
            })
            .catch(error => {
                setErrorMessage(error.message);
            });
    };

    return (
        <div className={styles.loginContainer}>
            <div className={styles.loginBox}>
                <h1 className={styles.loginTitle}>Sign In</h1>
                <input
                    className={styles.input}
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && passwordInputRef.current?.focus()}
                    type="email"
                    autoCapitalize="none"
                />
                <input
                    ref={passwordInputRef}
                    className={styles.input}
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleLogin()}
                />
                {errorMessage && (
                    <p className={styles.errorText}>{errorMessage}</p>
                )}
                <div
                    className={styles.button}
                    onClick={handleLogin}
                >
                    Login
                </div>

                <div
                    className={styles.button}
                    onClick={() => navigate(-1)}
                >
                    Back
                </div>

                <p
                    className={styles.createAccountText}
                    onClick={() => navigate('/create-account')}
                >
                    Create Account
                </p>
            </div>
        </div>
    );
};

export default Login;