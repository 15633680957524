import React, { useState, useEffect } from 'react';
import styles from './News.module.css';
import NewsData from "../../types/NewsData";

const News = () => {
    const [news, setNews] = useState<NewsData[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                // Simulate an API call to fetch news
                const newsData = [
                    { id: '1', title: 'New Update Released', content: 'We have released a new update for the platform...' },
                    { id: '2', title: 'Upcoming Maintenance', content: 'There will be scheduled maintenance on...' },
                    { id: '3', title: 'Feature Spotlight', content: 'Check out our new feature that allows you to...' },
                ];
                setNews(newsData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching news: ", error);
                setLoading(false);
            }
        };

        fetchNews();
    }, []);

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>News</h1>
            {loading ? (
                <p className={styles.loadingText}>Loading...</p>
            ) : (
                <div className={styles.newsList}>
                    {news.map(item => (
                        <div key={item.id} className={styles.newsItem}>
                            <h2 className={styles.newsTitle}>{item.title}</h2>
                            <p className={styles.newsContent}>{item.content}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default News;