import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, addDoc } from 'firebase/firestore';
import { firestore } from '../../App';
import Season from '../../types/Season';
import { Timestamp } from 'firebase/firestore';
import styles from './AdminSeasons.module.css';
import MeritEntry from '../Merits/MeritEntry';

const AdminSeason: React.FC = () => {
    const [seasons, setSeasons] = useState<Season[]>([]);
    const [selectedSeason, setSelectedSeason] = useState<Season | null>(null);
    const [showMeritEntry, setShowMeritEntry] = useState<boolean>(false);
    const [newSeasonName, setNewSeasonName] = useState<string>('');

    useEffect(() => {
        fetchSeasons();
    }, []);

    const fetchSeasons = async () => {
        const seasonsCollection = collection(firestore, 'seasons');
        const seasonsSnapshot = await getDocs(seasonsCollection);
        const seasonsList: Season[] = seasonsSnapshot.docs.map(doc => {
            const data = doc.data();
            return {
                id: doc.id,
                name: data.name,
                startDate: data.startDate as Timestamp,
                endDate: data.endDate as Timestamp | undefined,
                totalMerits: data.totalMerits,
                playerCount: data.playerCount,
                seasonSummary: data.seasonSummary,
                adminSummary: data.adminSummary,
                isActive: data.isActive
            };
        });
        setSeasons(seasonsList);
    };

    const handleSeasonSelect = (season: Season) => {
        setSelectedSeason(season);
        setShowMeritEntry(false); // Ensure MeritEntry is hidden when selecting a new season
    };

    const handleEndSeason = async (seasonId: string) => {
        const seasonRef = doc(firestore, 'seasons', seasonId);
        const endDate = Timestamp.now();
        await updateDoc(seasonRef, { endDate, isActive: false });
        setSeasons(seasons.map(season =>
            season.id === seasonId ? { ...season, endDate, isActive: false } : season
        ));
        setSelectedSeason(null);
    };

    const handleShowMeritEntry = () => {
        setShowMeritEntry(true);
    };

    const handleCancelMeritEntry = () => {
        setShowMeritEntry(false);
    };

    const handleCreateSeason = async () => {
        const newSeason: Omit<Season, 'id'> = {
            name: 'Season ' + (seasons.length + 3), //setting this at 3 since we have 2 seasons already
            startDate: Timestamp.now(),
            totalMerits: 0,
            playerCount: 0,
            isActive: true
        };

        const docRef = await addDoc(collection(firestore, 'seasons'), newSeason);
        setSeasons([...seasons, { id: docRef.id, ...newSeason }]);
        setNewSeasonName('');
        setSelectedSeason(null); // Clear selected season when a new one is created
    };

    const hasActiveSeason = seasons.some(season => !season.endDate);

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Season Management</h1>

            {!hasActiveSeason && (
                <div className={styles.createSeasonContainer}>
                    <button onClick={handleCreateSeason} className={styles.button}>
                        Create Season
                    </button>
                </div>
            )}

            <div className={styles.contentContainer}>
                <div className={styles.seasonListContainer}>
                    <h2 className={styles.subtitle}>Seasons</h2>
                    <ul className={styles.seasonList}>
                        {seasons.map(season => (
                            <li key={season.id} onClick={() => handleSeasonSelect(season)} className={styles.seasonItem}>
                                {season.name} {season.endDate && '(Ended)'}
                            </li>
                        ))}
                    </ul>
                </div>

                {selectedSeason && !showMeritEntry && (
                    <div className={styles.seasonSummaryContainer}>
                        <h2 className={styles.subtitle}>Season Summary</h2>
                        <p>Name: {selectedSeason.name}</p>
                        <p>Start Date: {selectedSeason.startDate.toDate().toLocaleDateString()}</p>
                        <p>End Date: {selectedSeason.endDate ? selectedSeason.endDate.toDate().toLocaleDateString() : 'Ongoing'}</p>
                        <p>Total Merits: {selectedSeason.totalMerits}</p>
                        <p>Player Count: {selectedSeason.playerCount}</p>
                        {!selectedSeason.endDate && (
                            <div className={styles.buttonContainer}>
                                <button onClick={handleShowMeritEntry} className={styles.button}>
                                    Enter Merits
                                </button>
                                <button
                                    onClick={() => handleEndSeason(selectedSeason.id)}
                                    className={styles.button}
                                >
                                    End Season
                                </button>
                            </div>
                        )}
                    </div>
                )}

                {showMeritEntry && selectedSeason && (
                    <MeritEntry
                        season={selectedSeason}
                        onSave={() => setShowMeritEntry(false)}
                        onCancel={handleCancelMeritEntry}
                    />
                )}
            </div>
        </div>
    );
};

export default AdminSeason;