import { v4 as uuidv4 } from 'uuid';

export class Player {
    id: string;            // Unique identifier for the player
    name?: string;          // Player's name
    email?: string;         // User's email
    codId?: string;         // COD ID for the player
    discordId?: string;     // Discord ID for the user
    verified?: boolean;     // Verification status
    totalMerits?: number;         // Total merits earned by the player across all seasons
    seasons?: PlayerSeason[];     // Array of seasons the player participated in

    constructor() {
        this.id = uuidv4();
    }

}

export interface PlayerSeason {
    seasonId: string;              // Reference to the season
    totalMerits: number;           // Total merits earned in that season
    rank: number;                  // Player's rank in the season
    meritEntries: MeritEntry[];    // Array of merit entries with timestamps
    participationDetails?: string; // Optional detailed stats or achievements in that season
}

export interface MeritEntry {
    date: string;               // Date of the merit entry, stored as a string (or could use Firebase Timestamp)
    meritsEarned: number;       // Merits earned in that entry
    description?: string;       // Optional description of the event or action that earned the merits
}

export default Player;