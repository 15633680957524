import React, { useState, useEffect, useCallback } from 'react';
import { firestore, collection, getDocs, query, where } from '../../App';
import Member from '../../types/Member';
import Tabs, {Tab} from '../base/tabs/Tabs';
import styles from './Users.module.css';
import { QuerySnapshot } from '../../App';
// Define the structure for the tabs

const Users: React.FC = () => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [members, setMembers] = useState<Member[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [totalUsers, setTotalUsers] = useState<number>(0);
    const [totalOtherUsers, setTotalOtherUsers] = useState<number>(0);
    const [activeTab, setActiveTab] = useState<string>('Users');

    const fetchStatistics = useCallback(async () => {
        try {
            const usersCollection = collection(firestore, 'users');
            const querySnapshot = await getDocs(usersCollection);

            const membersList: Member[] = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            })) as Member[];

            if (activeTab === 'Users') {
                const validationCount = membersList.filter(member => member.verified).length;
                setTotalUsers(validationCount);
            }
            const awaitingValidationCount = membersList.filter(member => !member.verified).length;
            setTotalOtherUsers(awaitingValidationCount);

        } catch (error) {
            console.error('Error fetching statistics: ', error);
        }
    }, [activeTab]);

    useEffect(() => {
        setSearchQuery('');
        fetchStatistics();
    }, [fetchStatistics]);

    const fetchAllUsers = useCallback(async () => {
        setLoading(true);
        try {
            const usersCollection = collection(firestore, 'users');
            let querySnapshot: QuerySnapshot;

            if (activeTab === 'Users') {
                const q = query(usersCollection, where('verified', '==', true));
                querySnapshot = await getDocs(q);
            } else if (activeTab === 'Awaiting Validation') {
                const q = query(usersCollection, where('verified', '==', false));
                querySnapshot = await getDocs(q);
            }

            const membersList: Member[] = querySnapshot!.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            })) as Member[];

            setMembers(membersList);
        } catch (error) {
            console.error('Error fetching members: ', error);
        } finally {
            setLoading(false);
        }
    }, [activeTab]);

    useEffect(() => {
        setSearchQuery('');
        fetchAllUsers();
    }, [fetchAllUsers]);

    const handleSearch = useCallback(async () => {
        if (searchQuery.trim() === '') {
            await fetchAllUsers(); // If the search query is empty, fetch all users
            return;
        }

        setLoading(true);
        try {
            const usersCollection = collection(firestore, 'users');
            let querySnapshot: QuerySnapshot | null = null; // Initialize with null

            if (activeTab === 'Users') {
                const q = query(usersCollection, where('codId', '==', searchQuery.trim()),
                    where('verified', '==', true));
                querySnapshot = await getDocs(q);
            } else if (activeTab === 'Awaiting Validation') {
                const q = query(usersCollection, where('codId', '==', searchQuery.trim()),
                    where('verified', '==', false));
                querySnapshot = await getDocs(q);
            }

            if (querySnapshot) {
                const membersList: Member[] = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                })) as Member[];

                setMembers(membersList);
            }
        } catch (error) {
            console.error('Error fetching members: ', error);
        } finally {
            setLoading(false);
        }
    }, [activeTab, searchQuery, fetchAllUsers]);

    const handleReset = () => {
        setSearchQuery(''); // Clear the search query first
        fetchAllUsers(); // Fetch all users after clearing the query
    };

    const renderItem = (item: Member) => (
        <div className={styles.row} key={item.id}>
            <div className={`${styles.cell} ${styles.codIdCell}`}>{item.codId}</div>
            <div className={`${styles.cell} ${styles.nameCell}`}>{item.name}</div>
            <div className={`${styles.cell} ${styles.emailCell}`}>{item.email}</div>
        </div>
    );

    const usersContent: React.ReactNode = (
        <div>
            <div className={styles.searchBarContainer}>
                <input
                    type="text"
                    placeholder="Search Users by COD ID"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className={styles.searchBarInput}
                />
                <button onClick={handleSearch} className={styles.searchButton}>
                    Search
                </button>
                <button onClick={handleReset} className={styles.resetButton}>
                    Reset
                </button>
            </div>

            {loading ? (
                <p className={styles.loadingText}>Loading...</p>
            ) : (
                <div>
                    <div className={styles.headerRow}>
                        <div className={`${styles.headerCell} ${styles.codIdHeader}`}>COD ID</div>
                        <div className={`${styles.headerCell} ${styles.nameHeader}`}>Name</div>
                        <div className={`${styles.headerCell} ${styles.emailHeader}`}>Email</div>
                    </div>

                    <div className={styles.table}>
                        {members.length > 0 ? (
                            members.map(renderItem)
                        ) : (
                            <p className={styles.noResultsText}>No members found</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );

    const otherUsersContent: React.ReactNode = (
        <div>
            <div className={styles.searchBarContainer}>
                <input
                    type="text"
                    placeholder="Search Awaiting Validation by COD ID"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className={styles.searchBarInput}
                />
                <button onClick={handleSearch} className={styles.searchButton}>
                    Search
                </button>
                <button onClick={handleReset} className={styles.resetButton}>
                    Reset
                </button>
            </div>

            {loading ? (
                <p className={styles.loadingText}>Loading...</p>
            ) : (
                <div>
                    <div className={styles.headerRow}>
                        <div className={`${styles.headerCell} ${styles.codIdHeader}`}>COD ID</div>
                        <div className={`${styles.headerCell} ${styles.nameHeader}`}>Name</div>
                        <div className={`${styles.headerCell} ${styles.emailHeader}`}>Email</div>
                    </div>

                    <div className={styles.table}>
                        {members.length > 0 ? (
                            members.map(renderItem)
                        ) : (
                            <p className={styles.noResultsText}>No users awaiting validation found</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );

    const tabs: Tab[] = [
        { label: 'Users', content: usersContent },
        { label: 'Awaiting Validation', content: otherUsersContent, badgeCount: totalOtherUsers },
    ];

    return (
        <div className={styles.container}>
            <div className={styles.mainContent}>
                <Tabs
                    tabs={tabs}
                    onTabChange={setActiveTab}
                />
            </div>

            <div className={styles.sidebarContainer}>
                <div className={styles.sidebar}>
                    <h2 className={styles.sidebarTitle}>{activeTab} Statistics</h2>
                    <p className={styles.sidebarStat}>
                        <span className={styles.sidebarLabel}>Total {activeTab}: </span>
                        {activeTab === 'Users' ? totalUsers : totalOtherUsers}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Users;