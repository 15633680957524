import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../App';
import Season from '../../types/Season';
import Player from '../../types/Player'; // Assume you have a Player type defined
import styles from './MeritEntry.module.css';

interface MeritEntryProps {
    season: Season;
    onSave: () => void;
    onCancel: () => void;
}


const MeritEntry: React.FC<MeritEntryProps> = ({ season }) => {
    const [players, setPlayers] = useState<Player[]>([]);
    const [meritData, setMeritData] = useState<{ [key: string]: number }>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchPlayers();
    }, []);

    const fetchPlayers = async () => {
        setLoading(true);
        try {
            const playersCollection = collection(firestore, 'players');
            const playersSnapshot = await getDocs(playersCollection);
            const playersList: Player[] = playersSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            })) as Player[];
            setPlayers(playersList);
        } catch (err) {
            setError('Error fetching players');
        } finally {
            setLoading(false);
        }
    };

    const handleMeritChange = (playerId: string, merits: number) => {
        setMeritData({
            ...meritData,
            [playerId]: merits,
        });
    };

    const handleSubmitMerits = async () => {
        try {
            for (const playerId in meritData) {
                const playerRef = doc(firestore, 'players', playerId);
                await updateDoc(playerRef, {
                    [`merits.${season.id}`]: meritData[playerId],
                });
            }
            alert('Merits submitted successfully!');
        } catch (err) {
            setError('Error submitting merits');
        }
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Enter Merits for {season.name}</h2>
            {loading ? (
                <p>Loading players...</p>
            ) : (
                <div className={styles.playerList}>
                    {players.map(player => (
                        <div key={player.id} className={styles.playerItem}>
                            <span>{player.name}</span>
                            <input
                                type="number"
                                placeholder="Enter merits"
                                value={meritData[player.id] || ''}
                                onChange={(e) => handleMeritChange(player.id, parseInt(e.target.value) || 0)}
                                className={styles.input}
                            />
                        </div>
                    ))}
                </div>
            )}
            {error && <p className={styles.errorText}>{error}</p>}
            <button onClick={handleSubmitMerits} className={styles.button}>Submit Merits</button>
        </div>
    );
};

export default MeritEntry;